.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.13);
  display: flex;
  justify-content: center;
  /*align-items: center;*/
  z-index: 10000;
  backdrop-filter: blur(5px);
  overflow: auto;
}

.popup {
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.popup-mobile {
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow-y: auto;
  max-height: 95%;
  width: 90%;
}
